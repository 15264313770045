import { SpaceBetween } from "@cloudscape-design/components";
import Container from "@cloudscape-design/components/container";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Header from "@cloudscape-design/components/header";
import React from "react";
import ExabonPage from "../components/exabon/page";

function HomeContent(): JSX.Element {
  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description="This is a generic description used in the header."
        >
          Header
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <Container
          header={
            <Header
              variant="h2"
              description="Container description"
            >
              Reach a global audience
            </Header>
          }
        >
          Container content
        </Container>
        <Container
          header={
            <Header
              variant="h2"
              description="Container description"
            >
              Set it and forget it
            </Header>
          }
        >
          Container content
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
}

export default function HomePage(): JSX.Element {
  return (
    <ExabonPage content={<HomeContent />} />
  );
}
