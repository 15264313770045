import TopNavigation from "@cloudscape-design/components/top-navigation";

export default function ExabonTopNavigation(): JSX.Element {
  return (
    <TopNavigation
      className="header"
      identity={{
        href: "/",
        title: "Exabon",
      }}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu"
      }}
    />
  );
}
