import Button from "@cloudscape-design/components/button";
import * as React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

export interface InternalLinkButtonProps {
  href: string;
  text: string;
}

export default function InternalLinkButton(props: InternalLinkButtonProps): JSX.Element {

  const navigate: NavigateFunction = useNavigate();

  return (
    <Button
      onClick={(clickEvent) => navigate(props.href)}
    >
      {props.text}
    </Button>
  );
}
