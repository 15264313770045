import AppLayout from "@cloudscape-design/components/app-layout";
import React from "react";
import ExabonTopNavigation from "./top-navigation";

export interface ExabonPageProps {
  content: React.ReactNode;
}

export default function ExabonPage(props: ExabonPageProps): JSX.Element {
  return (
    <React.Fragment>
      <ExabonTopNavigation />
      <AppLayout
        headerSelector="header"
        navigationHide={true}
        toolsHide={true}
        content={props.content}
      />;
    </React.Fragment>
  );
}
