import Alert from "@cloudscape-design/components/alert";
import ContentLayout from "@cloudscape-design/components/content-layout";
import ExabonPage from "../components/exabon/page";
import InternalLinkButton from "../components/internal-link-button";

function ErrorContent(): JSX.Element {
  return (
    <ContentLayout>
      <Alert
        type="error"
        header="Page not found"
        dismissible={false}
        action={<InternalLinkButton href="/" text="Go home" />}
      >
        The requested URL does not exist.
      </Alert>
    </ContentLayout>
  );
}

export default function ErrorPage(): JSX.Element {
  return <ExabonPage content={<ErrorContent />} />;
}
