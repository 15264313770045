import "@cloudscape-design/global-styles/index.css";
import { Router } from "@remix-run/router";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/error";
import HomePage from "./pages/home";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router: Router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
